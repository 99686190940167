export default [
  // [title, os link, mainnet, (rinkeby)],

  ['3 Words', 'https://opensea.io/collection/3words', '0xB77F0b25aF126FCE0ea41e5696F1E5e9102E1D77'],

  // ['888 by Kevin Abosch', 'https://opensea.io/collection/888-by-kevin-abosch'],

  ['Alien Frens', 'https://opensea.io/collection/alienfrensnft', '0x123b30E25973FeCd8354dd5f41Cc45A3065eF88C'],
  ['Apparitions by Aaron Penne', 'https://opensea.io/collection/apparitions-by-aaron-penne', '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270'],
  ['Archetype by Kjetil Golid', 'https://opensea.io/collection/archetype-by-kjetil-golid', '0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270'],
  ['Autobreeder (lite) by Harm van den Dorpel', 'https://opensea.io/collection/autobreeder-lite-by-harm-van-den-dorpel', '0x842D8B7B08C154ADc36A4f1186A0f401a10518EA'],
  ['Avid Lines', 'https://opensea.io/collection/avid-lines', '0xDFAcD840f462C27b0127FC76b63e7925bEd0F9D5'],
  ['Azuki', 'https://opensea.io/collection/azuki', '0xED5AF388653567Af2F388E6224dC7C4b3241C544'],
  ['Blitmap', 'https://opensea.io/collection/blitmap', '0x8d04a8c79cEB0889Bdd12acdF3Fa9D207eD3Ff63'],
  ['Bored Ape Kennel Club', 'https://opensea.io/collection/bored-ape-kennel-club', '0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623'],
  ['Bored Ape Yacht Club', 'https://opensea.io/collection/boredapeyachtclub', '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D'],
  ['Capsule House', 'https://opensea.io/collection/capsulehouse', '0xfcB1315C4273954F74Cb16D5b663DBF479EEC62e'],
  ['Channel-S0', 'https://opensea.io/collection/channel-s0', '0xa8f9b843c449c5d93a58400f8586599b8f336dbb'],
  ['Chromie Squiggle by Snowfro', 'https://opensea.io/collection/chromie-squiggle-by-snowfro', '0x059EDD72Cd353dF5106D2B9cC5ab83a52287aC3a'],
  ['CLASSIFIED | Holly Herndon', 'https://opensea.io/collection/classified-hollyherndon', '0x91Fba69Ce5071Cf9e828999a0F6006A7F7E2a959'],
  ['CLONE X - X TAKASHI MURAKAMI', 'https://opensea.io/collection/clonex', '0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B'],
  ['Clouds on Chains', 'https://opensea.io/collection/clouds-on-chains', '0x5ee9b9a4dda75b4736a5a92508bf206f9b653c2e'],
  ['Cool Cats NFT', 'https://opensea.io/collection/cool-cats-nft', '0x1A92f7381B9F03921564a437210bB9396471050C'],
  ['Creature World', 'https://opensea.io/collection/creatureworld', '0xc92cedDfb8dd984A89fb494c376f9A48b999aAFc'],
  ['Crypto Citizens', 'https://opensea.io/collection/cryptocitizensofficial', '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270'],
  ['CrypToadz by GREMPLIN', 'https://opensea.io/collection/cryptoadz-by-gremplin', '0x1CB1A5e65610AEFF2551A50f76a87a7d3fB649C6'],
  ['CryptoArte', 'https://opensea.io/collection/cryptoarte', '0xBACe7E22f06554339911A03B8e0aE28203Da9598'],
  ['CryptoBots', 'https://opensea.io/collection/cryptobots', '0xF7a6E15dfD5cdD9ef12711Bd757a9b6021ABf643'],
  ['Crypto.Chicks', 'https://opensea.io/collection/the-crypto-chicks', '0x1981CC36b59cffdd24B01CC5d698daa75e367e04'],
  ['Crypto Coven', 'https://opensea.io/collection/cryptocoven', '0x5180db8F5c931aaE63c74266b211F580155ecac8'],
  ['CryptoKitties', 'https://opensea.io/collection/cryptokitties', '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d'],
  ['CyberKongz', 'https://opensea.io/collection/cyberkongz', '0x57a204AA1042f6E66DD7730813f4024114d74f37'],
  ['CryptoPunks', 'https://opensea.io/collection/cryptopunks', '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB', '0x999426cb37bb8Ea786d3E24F6094004fad686f70'],
  ['CryptoSkulls', 'https://opensea.io/CryptoSkulls/cryptoskulls', '0xc1Caf0C19A8AC28c41Fe59bA6c754e4b9bd54dE9'],

  // !! missing from contract
  // ['CryptoTulips', 'https://opensea.io/collection/cryptotulips'],

  ['Decentraland', 'https://opensea.io/collection/decentraland', '0xF87E31492Faf9A91B02Ee0dEAAd50d51d56D5d4d'],
  ['Doodles', 'https://opensea.io/collection/doodles-official', '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e'],
  ['DotCom Seance', 'https://dotcomseance.com', '0x6CA044FB1cD505c1dB4eF7332e73a236aD6cb71C', '0x24Fe466A59A9d072a8fC0A4ad3f8396d91A34399'],

  // ['eBoy Blockbob', 'https://opensea.io/collection/eboy-blockbob'],

  ['Emoji Script by Travess Smalley', 'https://folia.app/works/2', '0xDCe09254dD3592381b6A5b7a848B29890b656e01', '0x95793c65c398D0a5EEb92d6b475f4E6a2044Bee1'],
  ['Endless Nameless by Rafaël Rozendaal', 'https://opensea.io/collection/endless-nameless-by-rafael-rozendaal', '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270'],

  // ['EtherRock'],
  // ['ETH Pixels', 'https://opensea.io/collection/ethpixels'],

  ['Fast Food Frens Collection', 'https://opensea.io/collection/fastfoodfrenscollection', '0x4721D66937B16274faC603509E9D61C5372Ff220'],

  // ['Fast Food Punks', 'https://opensea.io/collection/fast-food-punks'],

  ['Fidenza by Tyler Hobbs', 'https://opensea.io/collection/fidenza-by-tyler-hobbs', '0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270'],
  ['FOMO Dog Club', 'https://opensea.io/collection/fomo-dog-club', '0x90cfCE78f5ED32f9490fd265D16c77a8b5320Bd4'],
  ['Fragments of an Infinite Field by Monica Rizzolli', 'https://opensea.io/collection/fragments-of-an-infinite-field-by-monica-rizzolli', '0xa7d8d9ef8D8Ce8992Df33D8b8CF4Aebabd5bD270'],

  // ['Gutter Cat Gang', 'https://opensea.io/collection/guttercatgang'],

  ['Holly+ DAO Genesis Token', 'https://opensea.io/collection/hollyplus-dao-genesis-token', '0x11e1638d68d4eff1e60dde34710598882ac957c3'],
  ['Hashmasks', 'https://opensea.io/collection/hashmasks', '0xC2C747E0F7004F9E8817Db2ca4997657a7746928'],
  ['Impermanent Digital', 'https://opensea.io/collection/impermanent-digital', '0xa66f3bd98b4741bad68bcd7511163c6f855d2129'],
  ['Kaiju Kingz', 'https://opensea.io/collection/kaiju-kingz', '0x0c2E57EFddbA8c768147D1fdF9176a0A6EBd5d83'],
  ['Kudzu', 'https://opensea.io/collection/foliavirus', '0x9d413B9434c20C73f509505F7fbC6FC591bbf04A', '0xF80B749e0d03C005b8EfB7451BC6552555556149'],
  ['Lazy Lions', 'https://opensea.io/collection/lazy-lions', '0x8943C7bAC1914C9A7ABa750Bf2B6B09Fd21037E0'],
  ['lobsterdao', 'https://opensea.io/collection/lobsterdao', '0x026224A2940bFE258D0dbE947919B62fE321F042'],
  ['LOSTPOETS', 'https://opensea.io/collection/lostpoets', '0x4b3406a41399c7FD2BA65cbC93697Ad9E7eA61e5'],
  ["Markov's Dream by Harm van den Dorpel", 'https://opensea.io/collection/markovs-dream', '0x518f0c4a832b998ee793d87f0e934467b8b6e587'],
  ['Meebits', 'https://opensea.io/collection/meebits', '0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7'],
  ['Metasaurs by Dr. DMT', 'https://opensea.io/collection/metasaurs-nft', '0xF7143Ba42d40EAeB49b88DaC0067e54Af042E963'],
  ['MoonCats', 'https://opensea.io/collection/acclimatedmooncats', '0xc3f733ca98E0daD0386979Eb96fb1722A1A05E69'],
  ['Mutant Ape Yacht Club', 'https://opensea.io/collection/mutant-ape-yacht-club', '0x60E4d786628Fea6478F785A6d7e704777c86a7c6'],
  ['Mutant Garden Seeder', 'https://opensea.io/collection/mutant-garden-seeder', '0x20c70bdfcc398c1f06ba81730c8b52ace3af7cc3'],
  ['Neophyte MMXXII by Sterling Crispin', 'https://opensea.io/collection/neophyte-mmxxii-by-sterling-crispin', '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270'],
  // ['My Curio Cards', 'https://opensea.io/collection/curiocardswrapper'],
  ['Nouns', 'https://opensea.io/collection/nouns', '0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03'],
  ['NUXUI', 'https://opensea.io/collection/nuxui', '0x517e643f53eb3622fd2c3a12c6bfde5e7bc8d5ca'],
  ['PEACEFUL GROUPIES', 'https://opensea.io/collection/peaceful-groupies', '0x4f89Cd0CAE1e54D98db6a80150a824a533502EEa'],
  ['PhantaBear', 'https://opensea.io/collection/phantabear', '0x67D9417C9C3c250f61A83C7e8658daC487B56B09'],
  // ['Parallel Alpha', 'https://opensea.io/collection/parallelalpha'],
  // ['Petra Cortright - Room', 'https://opensea.io/collection/simco-drops-petra-cortright-room'],
  ['PixelMap', 'https://opensea.io/collection/pixelmap', '0x050dc61dFB867E0fE3Cf2948362b6c0F3fAF790b'],
  ['Pudgy Penguins', 'https://opensea.io/collection/pudgypenguins', '0xBd3531dA5CF5857e7CfAA92426877b022e612cf8'],
  ['PunkScapes', 'https://opensea.io/collection/punkscapes', '0x51Ae5e2533854495f6c587865Af64119db8F59b4'],
  ['Realiti', 'https://opensea.io/collection/realitic', '0x29b7315fc83172CFcb45c2Fb415E91A265fb73f2'],
  ['Rebel Society', 'https://opensea.io/collection/rebelsociety', '0x8CD3cEA52a45f30Ed7c93a63FB2b5C13B453d5A1'],
  ['RUGSTORE.exchange', 'https://opensea.io/collection/rugstore-exchange', '0x868193f5743436b7052549e6a3640580a9355f27'],
  ['Silk Road by Ezra Miller', 'https://opensea.io/collection/silk-road-by-ezra-miller', '0x53e4c0167ed855e96f562dbb911854d586f5cc07'],
  ['Solvency by Ezra Miller', 'https://opensea.io/collection/solvency-by-ezra-miller', '0x82262bfba3e25816b4c720f1070a71c7c16a8fc4'],
  ['SpaceFiller', 'https://opensea.io/collection/spacefiller', '0x63d6dab1835e463b142be01d91300ffb18bee3b3'],
  ['SupDucks', 'https://opensea.io/collection/supducks', '0x3Fe1a4c1481c8351E91B64D5c398b159dE07cbc5'],
  ['SCAMMER MARKET', 'https://opensea.io/collection/scammer-market', '0x4e2a87743368484ed798033e8fdc7cae442064b8'],
  ['The Doge Pound', 'https://opensea.io/collection/the-doge-pound', '0xF4ee95274741437636e748DdAc70818B4ED7d043'],
  ['The Sandbox', 'https://opensea.io/collection/sandbox', '0x5CC5B05a8A13E3fBDB0BB9FcCd98D38e50F90c38'],
  ['THE SHIBOSHIS', 'https://opensea.io/collection/theshiboshis', '0x11450058d796B02EB53e65374be59cFf65d3FE7f'],
  ['UNHUMAN COMPOSITIONS', 'https://opensea.io/collection/unhuman-compositions', '0x99f0501f97a2ad7dc5d8c99b56ea5ffbfbb60121'],
  ['Window by Jan Robert Leegte', 'https://opensea.io/collection/window-by-jan-robert-leegte', '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270'],
  ['WomenRise', 'https://opensea.io/collection/womenrise', '0x7f7685b4CC34BD19E2B712D8a89f34D219E76c35'],
  ['World of Women', 'https://opensea.io/collection/world-of-women-nft', '0xe785E82358879F061BC3dcAC6f0444462D4b5330'],
  ['WoW Pixies Official', 'https://opensea.io/collection/wow-pixies-v2', '0xB67812ce508b9fC190740871032237C24b6896A0'],
  ['Wrapped CryptoCats Official', 'https://opensea.io/collection/wrappedcryptocats', '0xd0e7Bc3F1EFc5f098534Bce73589835b8273b9a0'],
  ['Wrapped Pixereum', 'https://opensea.io/collection/wrapped-pixereum', '0x6f9d53BA6c16fcBE66695E860e72a92581b58Aed'],
  ['ZORBS by ZORA', 'https://opensea.io/collection/zorbs-v2', '0xca21d4228cdcc68d4e23807e5e370c07577dd152']
]
